import type {
  DataAccessLayerService,
  Hook,
  Identified,
  MixedOutput,
  QueryParams,
  LimitParams,
  SortParams,
  RemoteConfig,
  LocalDataAccesService,
} from './crud-service';
import { IAPIList, IAPIResource } from '../../..';

const TIME_TO_LIVE = 24;
const NB_DEFAULT_PARAMS = 2;
const DEFAULT_CRUD_OPTIONS = {
  id_key: '_id',
  default_params: {},
  exclude_offline_params: ['skip', 'limit'],
  take_care_of_user_profile: false,
  backup: {
    indexed_fields: [] as string[],
  },
};

type CRUDOptions = typeof DEFAULT_CRUD_OPTIONS & {
  path_name?: string;
  incomplete_sync_key?: string;
};

/**
 * NOTE: crudFactory can build specialised CRUD services.
 * A CRUD service is an instance of the CRUD class.
 * Usage:
 * ```ts
 * const placesListsCRUD = crudFactory('places_lists', {
 *   path_name: 'placesLists',
 *   default_params: { mode: 'compact' },
 *   take_care_of_user_profile: true,
 * })
 * ```
 */

/* @ngInject */
// eslint-disable-next-line max-params
export function CRUDFactory<T extends IAPIResource>(
  $q: ng.IQService,
  $http: ng.IHttpService,
  $log: ng.ILogService,
  localStorageService,
  dataStoreService,
  sfPOVService,
  pingService,
  dateService,
  logService,
  NB_CHUNK_ON_LIST: number,
  NB_PARAMS_SAVED_MAX: number
): CrudFactory<T> {
  class CRUDService implements DataAccessLayerService<T> {
    dataStoreService;
    logService;
    dataStore: LocalDataAccesService<T>;
    options: CRUDOptions;
    hooks: Record<string, Hook>;
    pathName: string;
    basePath: string;
    syncBasePath: string;
    updateDateKey: string;

    constructor(public name: string, options: Partial<CRUDOptions>) {
      this.options = {
        ...DEFAULT_CRUD_OPTIONS,
        ...options,
        exclude_offline_params: [
          ...DEFAULT_CRUD_OPTIONS.exclude_offline_params,
          ...(options?.exclude_offline_params ?? []),
        ],
      };

      this.dataStore = dataStoreService.initDataStore(
        name,
        this.options.backup
      );
      this.dataStoreService = dataStoreService;
      this.logService = logService;
      this.hooks = {};
      this.pathName = this.options.path_name || this.name;
      this.basePath = `/${this.pathName}`;
      this.syncBasePath = `/sync/${this.pathName}`;
      this.updateDateKey = `${this.name}_update_date`;
    }

    /**
     * HOOKS
     */
    //#region
    registerHook(name: string, hook: Hook): void {
      this.hooks[name] = hook;
    }

    private callHook<TC>(name: string, ...args): ng.IPromise<TC> {
      const data = this.hooks[name]
        ? this.hooks[name].apply<null, any[], any>(null, args)
        : args[0];

      return $q.when(data);
    }
    //#endregion

    /**
     * not SMART (local / remote / fallback)
     */
    //#region
    get(id: string, params: QueryParams = {}): ng.IPromise<T> {
      return this.dataStore
        .getLocal(id)
        .then((res) => res || this.getRemote(id, params))
        .catch(() => this.getRemote(id, params));
    }
    list(params: QueryParams): MixedOutput<T[]> {
      const needApiCall: boolean = dateService.isDelayExceeded(
        TIME_TO_LIVE,
        this.getLastUpdateDate()
      );
      const offline = this.listOfflineEntities(params);

      return {
        offline,
        online: needApiCall
          ? offline.catch(() => null).then(() => this.apiList(params))
          : null,
      };
    }

    delete(id: string, params: QueryParams = {}, config?: { pov: string }) {
      return this.deleteRemote(id, params, config).then(() => {
        this.dataStore.deleteLocal(id);
      });
    }
    //#endregion

    /**
     * LOCAL DATA (Backup)
     */
    //#region
    getLocal(id: string): ng.IPromise<Identified<T>> {
      return this.dataStore.getLocal(id);
    }

    queryLocal(
      params: QueryParams,
      limitParams?: LimitParams,
      sortParams?: SortParams,
      listComparator?: (...arg: any) => boolean
    ): ng.IPromise<Identified<T>[]> {
      const paramsToApply = this.discardQueryParams(
        this.options.exclude_offline_params,
        params
      );

      return this.dataStore.queryLocal(
        paramsToApply,
        limitParams,
        sortParams,
        listComparator
      );
    }

    listLocal(): ng.IPromise<Identified<T>[]> {
      // BUGS-2566: conditional log to avoid datadog spamming
      if (this.pathName && this.pathName.includes('place')) {
        this.logService.placeLog(
          `[BUGS-2566] crud-service.factory.ts | listLocal for ${this.pathName}`
        );
      }

      return this.dataStore.listLocal();
    }

    countLocal(): ng.IPromise<number> {
      return this.dataStore.countLocal();
    }

    saveLocal(id: string, entity: T): ng.IPromise<T> {
      const dataIndexedFieldValues = this.setIndexedFieldValues(
        this.options.backup.indexed_fields
      )(entity);

      return this.dataStore.saveLocal(id, dataIndexedFieldValues);
    }

    updateLocal(entity: T): ng.IPromise<Identified<T>> {
      const entityIndexedFieldValues = this.setIndexedFieldValues(
        this.options.backup.indexed_fields
      )(entity);

      return this.dataStore.updateLocal(entityIndexedFieldValues);
    }

    syncLocal(apiRes: IAPIList<T>, params: QueryParams = {}) {
      const idKey = this.options.id_key;
      const indexedFields = this.options.backup.indexed_fields;
      const nbIndexedFields = indexedFields.length;
      const nbParamsByEntry = nbIndexedFields + NB_DEFAULT_PARAMS;
      const nbParamsToSave = Math.round(NB_PARAMS_SAVED_MAX / nbParamsByEntry);

      return this.callHook<QueryParams>('refresh:offline-params', params)
        .then((offlineParams) => this.listOfflineEntities(offlineParams))
        .then((offlineEntities) => {
          // Modify backup entities with server entities.
          const apiIds = apiRes.entries.map((e) => this.getId(e));
          const localIds = offlineEntities.map((e) => this.getId(e));
          const notExistInApiFn = this.notExistInList(apiIds, 'id');
          const entitiesAdded = apiRes.entries.filter((entity) =>
            this.notExistInList(localIds, idKey)(entity)
          );
          const entitiesChanged = offlineEntities.reduce(
            this.getChanged(apiRes.entries),
            []
          );
          const entitiesDeleted = offlineEntities.reduce(
            this.getDeleted((entity) => notExistInApiFn(entity)),
            []
          );
          let entitiesToSave = ([] as T[])
            .concat(entitiesAdded, entitiesChanged)
            .map(this.setIndexedFieldValues(indexedFields));

          // Hook: before-refresh
          entitiesToSave = this.transformApiData(entitiesToSave);
          entitiesToSave = entitiesToSave.concat(entitiesDeleted);

          // SAVE to bdd
          return this.callHook<T[]>(
            'list:rewrite',
            entitiesToSave,
            apiRes
          ).then((newEntitiesToSave) => {
            const chunkEntities = this.getChunk(
              nbParamsToSave,
              newEntitiesToSave
            );
            const bulkPromises = chunkEntities.map(
              this.dataStore.bulkDocsLocal.bind(this.dataStore)
            );

            $log.debug(
              '[API] Sync',
              this.pathName,
              'Local :',
              newEntitiesToSave
            );
            return $q
              .all(bulkPromises)
              .then(() => this.callHook('refresh:after', newEntitiesToSave));
          });
        });
    }

    deleteLocal(id: string): ng.IPromise<Identified<T>> {
      return this.dataStore.deleteLocal(id);
    }

    deleteByExternalKey(indexName: string, value: string | number): void {
      return this.dataStoreService.deleteByIndex(this.name, indexName, value);
    }

    getHashWithContentKey(
      key: string,
      entities
    ): ng.IPromise<Record<string, T>> {
      const ids = this.getEntitiesContentsByKey(entities, key) as string[];

      return this.getHashByIds(ids);
    }

    private refreshLocal(
      apiRes: IAPIList<T>,
      params: QueryParams = {}
    ): ng.IPromise<IAPIList<T>[]> {
      // BUGS-2404: conditional log to avoid datadog spamming
      if (this.pathName && this.pathName.includes('campaigns')) {
        this.logService.info(
          `[BUGS-2404] crud-service.factory.ts | refreshLocal for ${this.pathName}`,
          {
            checklistsCount: apiRes.count,
            checklistIds: apiRes.entries.map((checklist) => checklist._id),
          },
          true
        );
      }

      return this.syncLocal(apiRes, params).then((res) => {
        localStorageService.set(this.updateDateKey, new Date().getTime());
        return res;
      });
    }

    // helper
    private getHashByIds(ids: string[]): ng.IPromise<Record<string, T>> {
      return ids.length
        ? this.queryLocal({ id: ids }).then((resData) =>
            resData.reduce((hash, data) => {
              hash[data.id] = data;
              return hash;
            }, {})
          )
        : $q.when({});
    }

    private listOfflineEntities(
      params: QueryParams
    ): ng.IPromise<Identified<T>[]> {
      const newParams = this.discardQueryParams(
        this.options.exclude_offline_params,
        params
      );

      // Get right fonction
      return Object.keys(newParams).length
        ? this.dataStore.queryLocal(newParams)
        : this.dataStore.listLocal();
    }
    //#endregion

    /**
     * REMOTE DATA (api / online)
     */
    //#region
    simpleApiList(
      url: string | undefined = this.basePath,
      params: QueryParams = {},
      useUserProfile: boolean,
      config?: { pov: string }
    ): ng.IPromise<IAPIList<T>> {
      return this.useUserPOV(url, useUserProfile, config)
        .then((povUrl) =>
          $http.post<IAPIList<T>>(
            povUrl,
            {
              ...params,
              ...((params.filters && {
                filters: JSON.stringify(params.filters),
              }) as Record<string, string>),
            },
            {
              headers: { 'X-HTTP-METHOD-OVERRIDE': 'GET' },
            }
          )
        )
        .then((res) => res.data as IAPIList<T>);
    }

    apiList(
      params: QueryParams,
      url: string = this.basePath,
      config?
    ): ng.IPromise<T[]> {
      const useUserPov = this.options.take_care_of_user_profile;

      return this.refreshListData(url, params, useUserPov, config);
    }

    listByChunks(
      url: string,
      params: QueryParams = {},
      chunkSize = NB_CHUNK_ON_LIST
    ): ng.IPromise<T[]> {
      const chunkQParams = {
        ...params,
        limit: chunkSize,
        skip: 0,
      };
      const reconstituedPayload = {
        count: 0,
        entries: [] as T[],
      };
      const recList = () =>
        $http
          .get<IAPIList<T>>(url, { params: chunkQParams })
          .then((response) => {
            reconstituedPayload.count = response.data.count;
            reconstituedPayload.entries = reconstituedPayload.entries.concat(
              response.data.entries
            );
            chunkQParams.skip += chunkSize;

            return reconstituedPayload.count > chunkQParams.skip
              ? recList()
              : reconstituedPayload;
          });

      return recList();
    }

    getRemote<T>(
      id: string,
      params: QueryParams = {},
      config?: { pov: string }
    ): ng.IPromise<T> {
      const userProfile = this.options.take_care_of_user_profile;

      params.mode = params.mode || 'compact';

      return this.useUserPOV(`${this.basePath}/${id}`, userProfile, config)
        .then((url) => $http.get<T>(url, { params }))
        .then((response) => response.data)
        .then((element) => this.setSQLStorageId(element))
        .catch((error) => {
          throw error.data || error;
        });
    }

    getRemoteWithDefaultPOV<T>(id: string, params: QueryParams = {}) {
      return this.useOrgPOV(`${this.basePath}/${id}`)
        .then((url) => $http.get<T>(url, { params }))
        .then((response) => response.data)
        .then((element) => this.setSQLStorageId(element))
        .catch((response) => {
          throw response.data;
        });
    }

    deleteRemote(
      id: string,
      params: QueryParams = {},
      config?: { pov: string }
    ): ng.IPromise<void> {
      const userProfile = this.options.take_care_of_user_profile;

      return this.useUserPOV(`${this.basePath}/${id}`, userProfile, config)
        .then((url) => $http.delete(url, { params }))
        .catch((response) => {
          throw response.data;
        });
    }

    saveRemote(id: string, entity: T, config: RemoteConfig): ng.IPromise<T> {
      const path = config.getPath
        ? config.getPath(this.basePath, id)
        : `${this.basePath}/${id}`;

      return this.useUserPOV(
        path,
        this.options.take_care_of_user_profile,
        config
      )
        .then((url) => $http.put<T>(url, entity))
        .then((response) => response.data)
        .catch((response) => {
          throw response.data;
        });
    }

    patchRemote(id: string, entity: T, config: RemoteConfig): ng.IPromise<T> {
      const path = config.getPath
        ? config.getPath(this.basePath, id)
        : `${this.basePath}/${id}`;

      return this.useUserPOV(
        path,
        this.options.take_care_of_user_profile,
        config
      )
        .then((url) => $http.patch<T>(url, entity))
        .then((response) => response.data)
        .catch((response) => {
          throw response.data;
        });
    }
    //#endregion

    /**
     * SYNC (Get online / persist local)
     */
    //#region
    // eslint-disable-next-line max-params
    private refreshListData(
      url: string,
      params: QueryParams = {},
      useUserPOV = false,
      config?: { usePostMethod: boolean; pov: string },
      onlineParams?: QueryParams
    ) {
      const requestParams = {
        ...this.options.default_params,
        ...(onlineParams || params),
      };

      return pingService
        .ping()
        .then(() => this.useUserPOV(url, useUserPOV, config))
        .then((povUrl) =>
          config && config.usePostMethod
            ? $http
                .post<IAPIList<T>>(povUrl, requestParams, {
                  headers: { 'X-HTTP-METHOD-OVERRIDE': 'GET' },
                })
                .then((res) => res.data)
            : this.listByChunks(povUrl, requestParams, NB_CHUNK_ON_LIST)
        )
        .then((entities) =>
          this.callHook<IAPIList<T>>('listApi:after', entities)
        )
        .then((apiRes) => {
          const getCallData = () =>
            this.callHook(
              'list:rewrite',
              this.transformApiData(apiRes.entries),
              apiRes
            );
          const updateIncompleteSyncKey = () => {
            if (this.options.incomplete_sync_key) {
              localStorageService.set(
                this.options.incomplete_sync_key,
                apiRes.count > apiRes.entries.length
              );
            }
            return this;
          };

          return this.refreshLocal(apiRes, params || {})
            .then(updateIncompleteSyncKey)
            .then(getCallData)
            .catch(getCallData);
        });
    }

    /**
     * Call an API point and sync limited data in the bdd It fetches all the
     * available data only if it doesn't exceed a limit defined in the backend
     * otherwise, it returns a subset
     **/
    synchronizeList(params: QueryParams = {}, url = this.syncBasePath) {
      // BUGS-2566: conditional log to avoid datadog spamming
      if (this.pathName && this.pathName.includes('place')) {
        this.logService.placeLog(
          `[BUGS-2566] crud-service.factory.ts | synchronizeList for ${this.pathName}`
        );
      }

      return this.refreshListData(
        url,
        undefined,
        undefined,
        {
          pov: 'organisation',
          usePostMethod: true,
        },
        params
      );
    }

    save(id: string, entity: Partial<T>, config: RemoteConfig): ng.IPromise<T> {
      const path = config.getPath
        ? config.getPath(this.basePath, id)
        : `${this.basePath}/${id}`;

      return this.useUserPOV(
        path,
        this.options.take_care_of_user_profile,
        config
      )
        .then((url) => $http.put<T>(url, entity))
        .then((response) => response.data)
        .then((element) => this.setSQLStorageId(element))
        .then((updatedEntity) =>
          this.dataStore
            .getLocal(id)
            .then(() => this.updateLocal(updatedEntity))
            .catch(() => this.saveLocal(id, updatedEntity))
            .then(() => updatedEntity)
        )
        .catch((response) => {
          throw response.data;
        });
    }

    refreshEntity(
      id: string,
      params: QueryParams = {},
      config?: { pov: string }
    ): ng.IPromise<T> {
      return this.getRemote<T>(id, params, config).then((entity) => {
        return this.updateLocal(entity);
      });
    }
    //#endregion

    /**
     * HELPERS
     */
    //#region
    private getEntitiesContentsByKey(entities: T[], key: string): unknown[] {
      return entities
        .filter((entity) => !!entity.contents[key])
        .map((entity) => entity.contents[key]);
    }

    private getLastUpdateDate(): Date | null {
      const lastUpdateDate = localStorageService.get(this.updateDateKey);

      return lastUpdateDate ? new Date(lastUpdateDate) : null;
    }

    private discardQueryParams(
      keysToDiscard: string[],
      params: QueryParams
    ): QueryParams {
      return Object.keys(params || {}).reduce((output, paramKey) => {
        if (keysToDiscard.indexOf(paramKey) === -1) {
          output[paramKey] = params[paramKey];
        }
        return output;
      }, {});
    }

    useUserPOV(url: string, useUserProfile: boolean, config?: { pov: string }) {
      return useUserProfile
        ? sfPOVService.pBuildURLByProfile(url)
        : sfPOVService.pBuildURL(url, config);
    }

    useOrgPOV(url: string): ng.IPromise<string> {
      return sfPOVService.pBuildURL(url, { pov: 'organisation' });
    }

    private transformApiData(entities: T[]): Identified<T>[] {
      return entities.map((entity) => this.setSQLStorageId(entity));
    }

    private setSQLStorageId(entity): Identified<T> {
      entity.id = this.getId(entity);
      return entity;
    }

    private getId(entity): string {
      return entity.id || entity._id;
    }

    private notExistInList(ids: string[], key: string): (entity: T) => boolean {
      return (entity) => ids.indexOf(entity[key] as string) === -1;
    }

    private setIndexedFieldValues(indexed: string[]) {
      return (data) => {
        indexed.forEach((field) => {
          data[field] =
            data[field] || (data.contents ? data.contents[field] : null);
        });
        return data;
      };
    }

    private getChunk(nbMax: number, entities: T[]): T[][] {
      const chunkData = [] as T[][];

      for (
        let currentIndex = 0;
        currentIndex < entities.length;
        currentIndex += nbMax
      ) {
        chunkData.push(entities.slice(currentIndex, currentIndex + nbMax));
      }
      return chunkData;
    }

    private getChanged(entities: T[]): (output: T[], entity: T) => T[] {
      const ids = entities.map(this.getId);

      return (output, entity) => {
        const curEntity = entities[ids.indexOf(this.getId(entity))];

        if (!curEntity || !curEntity.modified_date) {
          return output;
        }

        const hasApiDataChanged = dateService.isGreaterThan(
          curEntity.modified_date,
          entity.modified_date
        );

        const hasApiStatisticsChanged = curEntity?.statistics
          ? dateService.isGreaterThan(
              curEntity?.statistics?.modified_date,
              entity?.statistics?.modified_date
            )
          : false;

        const isChange = hasApiDataChanged || hasApiStatisticsChanged;

        return isChange ? output.concat(curEntity) : output;
      };
    }

    private getDeleted(checkFn) {
      return (output, entity) =>
        checkFn(entity) && !entity.localStatus
          ? output.concat(this.setDeleted(entity))
          : output;
    }

    private setDeleted(entity) {
      entity._deleted = true;
      return entity;
    }
    //#endregion
  }

  return (name: string, options: Partial<CRUDOptions>) =>
    new CRUDService(name, options);
}

export type CrudFactory<T = unknown> = (
  name: string,
  options: Partial<CRUDOptions>
) => DataAccessLayerService<T>;
