import { CommentsResourceType } from '../../../../comments/services/comments-api/comments-api.factory';
import { PICTURE_SYSTEM_IDENTIFIERS } from '../../../../constants/picture-system.constants';

const STORAGE_QUESTION_PHOTO_ACTIVE_KEY = 'question_photo_active';
const LIBRARY_MAX_NB = 10;
const PHOTO_TYPE = 'image/jpeg';

export const QuestionPhotoComponent = {
  bindings: {
    question: '<',
    report: '<',
    hasError: '<',
    questionOptions: '<',
    questionFolderName: '<',
    answers: '=',
    form: '<',
    place: '<',
    isDisabled: '<?',
  },
  require: {
    sfQuestionForm: '^sfQuestionForm',
  },
  templateUrl:
    'missions/components/Form-questions/question-photo/question-photo.html',
  // eslint-disable-next-line max-params
  controller: function QuestionPhotoController(
    $q,
    pubSubService,
    $translate,
    $window,
    keyboardService,
    modalService,
    objectIdService,
    filesSystemService,
    imageService,
    sentryService,
    actionSheetService,
    localStorageService,
    pictureSystemService,
    platformService,
    formsService,
    LIFECYCLE_EVENTS,
    SF_IMAGE_SIZES,
    imageSourceService,
    filesService,
    popupService,
    dateFormatService,
    profileService,
    commentsFactory,
    tagsSelectorService,
    tagsService
  ) {
    'ngInject';

    const getPictureSizes = (path, bigPath) => ({
      small: path,
      big: path || bigPath,
    });

    this.commentsService = commentsFactory(CommentsResourceType.ANSWERS);

    this.picturesHash = {};
    this.tags = [];

    profileService.getProfile().then((profile) => (this.profile = profile));
    // Fix lifecycle errors
    pubSubService.subscribe(LIFECYCLE_EVENTS.RESUME_RESULT, (data) =>
      this.onLifecycleResume(data)
    );
    pubSubService.subscribe('TAGS_UPDATED', ({ answerId, tagsIds }) => {
      const answer = this.answers.find(({ _id }) => _id === answerId);
      if (answer && answer.temp) {
        answer.temp.tags_ids = tagsIds;
      }
    });

    this.$onInit = () => {
      this.tagsLoading = true;
      this.tagsRequired = this.question.tagsRequired;

      tagsSelectorService
        .getTags()
        .then((tags) => {
          this.tags = tags;
        })
        .finally(() => (this.tagsLoading = false));

      this.isBrowser = platformService.isBrowser();

      this.picturesHash = {};
      this.commentsHash = {};
      this.folderName = this.questionFolderName.toString();
      this.processing = false;
      this.isReportSent = !this.report.localStatus;
      this.isEditionDisabledByValidation =
        this.reportIsSend &&
        this.question.validation &&
        this.report.contents.state !== 'draft';

      this.isNew =
        this.report.localStatus === 'draft' && !this.report.saved_date;

      if (!this.isNew) {
        this.initCommentsHash();
        this.getPictureTags();
      }

      const promises = [
        this.getLocalPicturesHash(),
        this.getRemotePicturesHash(),
      ];

      return $q.all(promises).then(([localHash, remoteHash]) => {
        this.picturesHash = Object.assign({}, localHash, remoteHash);
      });
    };

    this.getPictureTags = () => {
      const fileIds = this.answers.map(({ values }) => values[0].value);
      fileIds.map((fileId) => {
        tagsService.getFileTags(fileId).then((tags) => {
          const answer = this.answers.find(
            ({ values }) => values[0].value === fileId
          );
          if (answer) {
            answer.temp = {
              tags_ids: tags,
            };
          }
        });
      });
    };

    this.initCommentsHash = () => {
      if (!this.isNew) {
        const promisses = this.answers.map(({ _id }) =>
          this.commentsService.list(_id)
        );

        return $q.all(promisses).then((answersComments) => {
          this.commentsHash = answersComments.reduce(
            (hash, { entries, count }) => {
              if (!count) {
                return hash;
              }

              const answerId = entries.at(0).contents.answer_id;

              hash[answerId] = count;

              return hash;
            },
            {}
          );
        });
      }

      $q.when(this.commentsService.getLocalList()).then((comments) => {
        this.commentsHash = comments.reduce((hash, { answer_id: answerId }) => {
          if (!hash[answerId]) {
            hash[answerId] = 0;
          }

          hash[answerId]++;

          return hash;
        }, {});
      });
    };

    this.onLifecycleResume = (data) => {
      const activeQuestion = localStorageService.get(
        STORAGE_QUESTION_PHOTO_ACTIVE_KEY
      );
      const result = data.result;

      if (
        !activeQuestion ||
        activeQuestion.question_id !== this.question._id ||
        !result
      ) {
        return false;
      }

      this.processing = true;

      return this.handlePictureAnswers(result).finally(() => {
        this.processing = false;
        localStorageService.remove(STORAGE_QUESTION_PHOTO_ACTIVE_KEY);
      });
    };

    this.checkImagesQuantity = () => {
      this.setFormValid();
      if (this.answers.length > this.question.maximum) {
        this.setFormInvalid();
      }
    };

    this.canTakeNewPhoto = () =>
      this.questionOptions.max === 'Infinity'
        ? true
        : this.questionOptions.max > this.answers.length;

    this.howManyPhotosCanBeTaken = () => {
      const maximum = formsService.computeMaximum(
        this.question,
        this.form,
        this.report.contents.answers,
        { place: this.place }
      );

      return maximum === 'Infinity'
        ? LIBRARY_MAX_NB
        : this.getNbRemainingPictures(maximum);
    };

    this.getNbRemainingPictures = (maximum) => {
      const nbRemaining = maximum - this.answers.length;

      return LIBRARY_MAX_NB <= nbRemaining ? LIBRARY_MAX_NB : nbRemaining;
    };

    this.addComment = (answer) => {
      popupService
        .prompt({
          title: $translate.instant('TASK_PHOTO_VIEWER_ADD_COMMENT_TITLE'),
          placeholder: $translate.instant(
            'TASK_PHOTO_VIEWER_ADD_COMMENT_PLACEHOLDER'
          ),
          submit: $translate.instant('TASK_PHOTO_VIEWER_ADD_COMMENT_SUBMIT'),
          required: true,
          long: true,
        })
        .promptPromise.then((commentField) => {
          const comment = this.commentsService.buildComment(
            this.profile,
            commentField,
            { answer_id: answer._id }
          );

          $q.when(
            this.commentsService.create(answer._id, comment, this.isNew)
          ).then(() => this.initCommentsHash());
        });
    };

    this.openTagSelectorModal = (answers) => {
      const images = answers.map((answer) => {
        const url = this.getPictureUrl(answer, 'small');

        return {
          ...imageSourceService.create({ url }, false),
          answerId: answer._id,
          temp: answer.temp,
        };
      });

      return tagsSelectorService
        .openTagSelector(images, this.tagsRequired)
        .then((tagsIds) => {
          const answerIds = Object.keys(tagsIds);
          answerIds.map((answerId) => {
            const answer = this.answers.find(({ _id }) => _id === answerId);
            answer.temp.tags_ids = Object.keys(tagsIds[answerId]);
          });
        });
    };

    this.viewPicture = (answer) => {
      const template = `
        <sf-preview-modal
          on-close="$ctrl.onPreviewClose()"
          content-name="$ctrl.contentName"
          images="$ctrl.images"
          params="$ctrl.params"
          is-local-mode="$ctrl.isLocalMode"
          media-comment-allowed="false"
        ></sf-preview-modal>
      `;

      const url = this.getPictureUrl(answer, 'big');
      let tagsIds = [];

      if (answer.temp && answer.temp.tags_ids) {
        tagsIds = answer.temp.tags_ids;
      }

      let fileId;
      if (!this.isLocalMode) {
        fileId = answer.values[0].value;
      }

      const images = [
        {
          ...imageSourceService.create({ url }, false),
          answerId: answer._id,
          tagsIds,
          id: fileId,
          canEditTags: true,
          tagsRequired: this.tagsRequired,
        },
      ];

      const user = this.profile.contents;
      let translations = {};

      if (this.form && this.form.i18n) {
        translations = this.form.i18n;
      } else if (this.report && this.report.i18n) {
        translations = this.report.i18n;
      }

      const params = [
        {
          report_date: dateFormatService.getDateAndTimeFormatted(new Date()),
          campaign_title: this.form ? this.form.contents.title : null,
          question_title: this.question.title,
          user_name: user ? `${user.firstName} ${user.lastName}` : null,
          place_name: this.place ? this.place.name : null,
          translations,
        },
      ];

      const bindings = {
        images,
        contentName: this.form ? this.form.contents.title : '',
        params,
        isLocalMode: this.report.localStatus === 'draft',
        onPreviewClose: () => {
          this.initCommentsHash();
          bindings.onClose();
        },
      };

      return modalService.open(template, bindings);
    };

    // Take picture
    // ---------------
    this.takePicture = () => {
      const sources = this.question.fields[0].sources;
      const typePromise =
        sources.length === 1
          ? $q.when(sources[0])
          : pictureSystemService.selectSourceType([
              PICTURE_SYSTEM_IDENTIFIERS.LIBRARY,
              PICTURE_SYSTEM_IDENTIFIERS.IMAGE,
            ]);

      keyboardService.hide();

      return typePromise
        .then((type) => {
          const isLibrary = type === 'library';

          if (!isLibrary) {
            localStorageService.set(STORAGE_QUESTION_PHOTO_ACTIVE_KEY, {
              question_id: this.question._id,
            });
          }

          return pictureSystemService.getPictureFromSource(
            type,
            this.howManyPhotosCanBeTaken()
          );
        })
        .then(this.handlePictureAnswers)
        .finally(() => {
          localStorageService.remove(STORAGE_QUESTION_PHOTO_ACTIVE_KEY);
        });
    };

    /**
     * Handles pictures that come from the device camera or the image picker plugins
     * @param {Array} pathsOrFiles - Pictures path
     */
    // TODO refactor to ts, make sure FIle that comes from camera properly processed
    this.handlePictureAnswers = (pathsOrFiles) =>
      $q
        .all(
          pathsOrFiles.map((pathOrFile) => {
            return this.handlePathOrFile(pathOrFile)
              .then((fileInfo) => this.addFileToAnswers(fileInfo, 'file'))
              .then((answer) => $q.all([answer, this.getLocalPath(answer)]))
              .then(([answer, localPath]) => {
                this.picturesHash[answer._id] = getPictureSizes(localPath);
                return answer;
              })
              .then((answer) => {
                this.setFormDirty();
                return answer;
              })
              .catch((err) => {
                const reportContents = this.report.contents || {};

                sentryService.captureMessage('Capture photo failed', {
                  extra: {
                    report_id: this.report._id,
                    form_id: reportContents.form_id,
                    place_id: reportContents.place_id,
                    error: err,
                  },
                });
                throw err;
              });
          })
        )
        .then((answers) => {
          if (this.tagsRequired) {
            this.openTagSelectorModal(answers).catch(() =>
              answers.map((answer) => this.removeAnswer(answer))
            );
          }
        });

    this.handlePathOrFile = (pathOrFile) => {
      const fileId = objectIdService.create();
      if (typeof pathOrFile === 'string') {
        return pictureSystemService
          .movePicture(pathOrFile, this.folderName, fileId)
          .then((fileInfo) => {
            const info = {
              fileId,
              name: fileInfo.name,
              path: pathOrFile,
              blob: fileInfo.blob,
              tags_ids: [], // might not exist
            };
            return info;
          });
      }

      return filesService
        .saveTempFile(pathOrFile, {
          path: this.folderName,
          name: `${fileId}.jpg`,
          directory: filesSystemService.getDeviceFilePath(),
        })
        .then((fileInfo) => {
          // filesSystemService.saveInGallery(fileInfo.uri).catch(() => null); // sometimes draft images are lost on iOS
          const info = {
            fileId,
            name: fileInfo.name,
            path: fileInfo.uri,
            blob: fileInfo.file,
            tags_ids: [],
          };
          return info;
        });
    };

    /**
     * Handles pictures that come from the html file upload
     * @param {Array} files - Html Files
     */
    this.onPictureSelected = (files) => {
      const answers = files.map((file) => {
        const { name } = file;
        const fileId = objectIdService.create();
        const fileInfos = {
          fileId,
          blob: file,
          path: $window.URL.createObjectURL(file),
          name,
          tags_ids: [],
        };

        const answer = this.addFileToAnswers(fileInfos, 'blob');

        this.picturesHash[answer._id] = getPictureSizes(fileInfos.path);
        this.setFormDirty();

        return answer;
      });
      if (this.tagsRequired) {
        this.openTagSelectorModal(answers).catch(() =>
          answers.map((answer) => this.removeAnswer(answer))
        );
      }
    };

    this.addFileToAnswers = (
      { fileId, name, path, blob, tags_ids },
      fileType
    ) => {
      const fields = this.question.fields;
      const values = [
        { value: fileId, field_id: fields[0]._id },
        { value: name, field_id: fields[1]._id },
        { value: PHOTO_TYPE, field_id: fields[2]._id },
      ];

      this.answers = this.sfQuestionForm.addAnswer(values, this.answers, {
        type: 'image',
        temp: {
          needSync: true,
          filePath: fileType === 'blob' ? path : null,
          fileBlob: blob,
          fileType,
          tags_ids,
        },
      });

      const answer = this.answers.filter(
        (answer) => answer.values[0].value === fileId
      )[0];

      this.checkImagesQuantity();

      return answer;
    };

    // Delete
    // ---------------
    this.deletePicture = (answer) => {
      var defer = $q.defer();
      const onCancelClick = () => defer.reject();
      const onDestructiveClick = () => {
        this.removeAnswer(answer);
        this.commentsService.deleteAllLocalComments(answer._id);

        defer.resolve();
      };
      const actionSheetConfig = {
        title: $translate.instant('TASK_PHOTO_VIEWER_DELETE_TITLE'),
        cancelText: $translate.instant('TASK_PHOTO_VIEWER_DELETE_CANCEL'),
        destructiveText: $translate.instant('TASK_PHOTO_VIEWER_DELETE_CONFIRM'),
        isValidation: true,
      };

      actionSheetService.open(
        null,
        actionSheetConfig,
        onCancelClick,
        onDestructiveClick
      );

      return defer.promise;
    };

    this.removeAnswer = (answer) => {
      this.setFormDirty();
      this.answers = this.sfQuestionForm.removeAnswer(answer._id, this.answers);
      this.picturesHash[answer._id] = null;
      this.checkImagesQuantity();
    };

    this.getRemotePicturesHash = () =>
      $q
        .all(
          this.answers
            .filter((answer) => {
              return !(answer.temp && answer.temp.needSync);
            })
            .map(this.getRemotePicture)
        )
        .then((pictures) =>
          pictures.reduce((hash, picture) => {
            hash[picture._id] = picture;
            return hash;
          }, {})
        );

    this.getRemotePicture = (answer) =>
      imageService
        .getSizesHashFromId(answer.values[0].value, [
          SF_IMAGE_SIZES.SQUARE_SMALL,
          SF_IMAGE_SIZES.RECTANGLE_MEDIUM,
        ])
        .then((urls) => ({
          _id: answer._id,
          small: urls[SF_IMAGE_SIZES.SQUARE_SMALL],
          big: urls[SF_IMAGE_SIZES.RECTANGLE_MEDIUM],
        }));

    this.getLocalPicturesHash = () =>
      this.answers
        .filter((answer) => {
          return answer.temp && answer.temp.needSync;
        })
        .reduce(async (hashPromise, answer) => {
          const { filePath } = answer.temp;
          const hash = await hashPromise;
          const path = filePath || (await this.getLocalPath(answer));

          hash[answer._id] = getPictureSizes(path);

          return hash;
        }, $q.resolve({}));

    this.getLocalPath = (answer) => {
      const answerFileWithExtension = answer.values[1];
      const extension = filesSystemService.getExtension(
        answerFileWithExtension.value
      );
      const ext = extension || '';
      return filesSystemService
        .computeFileDataPath(
          this.questionFolderName,
          answer.values[0].value,
          ext
        )
        .then((localPath) => {
          // computeFileDataPath adds a dot '.' at the end event if extension ''
          const path = extension ? localPath : localPath.replace(/\.$/, '');
          return path;
        });
    };

    this.getPictureUrl = (answer, size = 'small') => {
      const answerPictures = this.picturesHash[answer._id] || {};
      const path = answerPictures[size];

      if (path) {
        const type = answer.temp
          ? answer.temp.type || answer.temp.fileType
          : answer.values[2].value;

        return type === 'blob' ? path : platformService.convertFileSrc(path);
      }

      return undefined;
    };

    this.setFormValid = () => {
      const photoForm = this[`photo_${this.question._id}`];

      return photoForm ? photoForm.$setValidity('max_pictures', true) : false;
    };

    this.setFormInvalid = () => {
      const photoForm = this[`photo_${this.question._id}`];

      return photoForm ? photoForm.$setValidity('max_pictures', false) : false;
    };

    this.setFormDirty = () => {
      const photoForm = this[`photo_${this.question._id}`];

      return photoForm ? photoForm.$setDirty(true) : false;
    };

    this.getPreviewUrl = (answer) => {
      const size = this.isBrowser ? 'big' : undefined;

      return this.getPictureUrl(answer, size);
    };
  },
};
