import moment from 'moment';

export class MonthYearSelectorController {
  constructor(objectIdService, platformService) {
    'ngInject';
    this.moment = moment;
    // without unique id click on the labels stop working at some point
    this.id = objectIdService.create();
    this.isBrowser = platformService.isBrowser();
    this.inputId = this.id + '-monthSelector';

    this.resetModel()
  }

  $onInit() {
    this.updateModel();
  }

  $onChanges(changes) {
    if (changes.date) {
      this.updateModel();
    }
  }

  resetModel() {
    this.model = new Date();
  }

  updateModel() {
    this.model = this.date ? this.moment(this.date).toDate() : new Date();
  }

  onSelectChange() {
    if (!this.model) {
      this.resetModel();
    }

    return this.onSelect({ momentDate: this.moment(this.model) });
  }
}
